.c-contents {
  font-size: 20px;
  background: $grey;
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: scroll;
  transition: transform 0.5s ease-in-out;
  max-width: 420px;
  min-width: 100%;

  @include breakpoint($med-viewport) {
    min-width: initial;
  }

  &.close {
    transform: translateX(100%);
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin: 0 10px;
  }

  &__headline {
    font-size: inherit;
  }

  ol {
    margin: 0;
    list-style: none;
    counter-reset: counter;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  li {
    padding: 10px 10px 10px 60px;
    counter-increment: counter;
    margin: 0;
    position: relative;
    border-bottom: 1px solid $grey-dark;
    cursor: pointer;

    &::before {
      content: counter(counter) "";
      color: $blue-dark;
      font-weight: bold;
      position: absolute;
      right: calc(100% - 40px);
      top: 50%;
      transform: translateY(-50%);
    }

    &.active {
      background: $white;
    }
  }
}

.darkmode {
  .c-contents {
    background: $black;
    color: $white;

    li.active {
      background: $grey-ultradark;
    }
  }
}
