.c-subnav {
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
  font-size: 20px;
}

.fluid-view {
  .c-subnav {
    display: block;
  }
}
