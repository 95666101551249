.c-papernavigation {
  background: $white;
  transition: background-color 0.5s linear, color 0.5s linear;

  i {
    pointer-events: none;
  }
}

.darkmode {
  .c-papernavigation {
    background: $grey-ultradark;
    color: $white;
  }
}
