.c-button {
  background: transparent;
  border: 0;
  font-size: 20px;
  margin: 0;
  padding: 10px;
  cursor: pointer;
  outline: 0;
  transition: color 0.5s linear
}

.darkmode {
  .c-button {
    color: $white;
  }
}