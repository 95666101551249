.documents {
  display: none;
  margin: 0 auto;
  padding: 0;

  img {
    display: block;
    height: auto;
    width: 100%;
  }

  > a,
  > span {
    display: block;
    overflow: hidden;
    padding: $grid-padding $grid-padding 0;
    position: relative;
    text-decoration: none;

    @include breakpoint($small-viewport) {
      float: left;
      width: 50%;
      padding: $grid-padding;
    }
  }

  h4,
  h5 {
    margin-bottom: $grid-padding;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  p {
    color: $black;
    font-size: 14px;
  }

  a span {
    color: $black;
    float: right;
    font-family: $helvetica;
    font-size: 14px;
    margin: calc(#{$grid-padding} / 2) 0;
  }

  &.handouts {
    text-align: center;

    span {
      bottom: 0;
      font-weight: normal;
      margin: $grid-padding calc(#{$grid-padding} * 2);
      position: absolute;
      right: 0;

      @include breakpoint($small-viewport) {
        margin-bottom: calc(#{$grid-padding} * 2);
      }
    }
  }
}

#paper {
  display: none;
}

.pdf-view {
  #papers {
    display: none;
  }

  #paper {
    display: block;
    margin: 0 auto;
    padding: 0;
    line-height: 0;
  }

  #pdf-viewer {
    width: 100%;
    margin: auto;
    height: calc(100vh - 100px);
    border: 0;
    line-height: 0;
  }
}

#fluid-paper {
  display: none;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
  min-width: 100vw;
  align-items: center;
}

.fluid-view {
  #papers {
    display: none;
  }

  #fluid-paper {
    display: flex;
  }
}

.snippet-spinner {
  text-align: center;
  margin: 30px auto;
  display: block;
  color: $blue-dark;

  &.hidden {
    display: none;
  }
}

.nocourses {
  text-align: center;
  margin: 0 auto;
  max-width: 400px;
  padding: 16px;

  &.hidden {
    display: none;
  }
}
