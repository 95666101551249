input[type=range] {
  margin: 11px 0;
  width: 100%;
  -webkit-appearance: none;
  background: transparent;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  background: #007fba;
  border: 0px solid #010101;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  cursor: pointer;
  height: 4px;
  width: 100%;
}

input[type=range]::-webkit-slider-thumb {
  background: #ffffff;
  border: 0.1px solid #000000;
  border-radius: 13px;
  box-shadow: 0px 0px 2.9px #000000, 0px 0px 0px #0d0d0d;
  cursor: pointer;
  height: 26px;
  margin-top: -11px;
  width: 26px;
  -webkit-appearance: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #0090d3;
}

input[type=range]::-moz-range-track {
  background: #007fba;
  border: 0px solid #010101;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  cursor: pointer;
  height: 4px;
  width: 100%;
}

input[type=range]::-moz-range-thumb {
  background: #ffffff;
  border: 0.1px solid #000000;
  border-radius: 13px;
  box-shadow: 0px 0px 2.9px #000000, 0px 0px 0px #0d0d0d;
  cursor: pointer;
  height: 26px;
  width: 26px;
}

input[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  color: transparent;
  cursor: pointer;
  height: 4px;
  width: 100%;
}

input[type=range]::-ms-fill-lower {
  background: #006ea1;
  border: 0px solid #010101;
  border-radius: 4px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

input[type=range]::-ms-fill-upper {
  background: #007fba;
  border: 0px solid #010101;
  border-radius: 4px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

input[type=range]::-ms-thumb {
  background: #ffffff;
  border: 0.1px solid #000000;
  border-radius: 13px;
  box-shadow: 0px 0px 2.9px #000000, 0px 0px 0px #0d0d0d;
  cursor: pointer;
  height: 26px;
  height: 4px;
  width: 26px;
}

input[type=range]:focus::-ms-fill-lower {
  background: #007fba;
}

input[type=range]:focus::-ms-fill-upper {
  background: #0090d3;
}
