$img-height: 38px;

.head {
  background: #fff;
  background: linear-gradient(#fbfbfb 0%, $white 60%); /* Standard syntax */
  min-height: 70px;
  overflow: hidden;
  padding: $grid-padding * 2 $grid-padding;
  position: relative;
  text-align: center;
  z-index: 3;

  @include breakpoint($small-viewport) {
    padding: $grid-padding * 2;
  }

  &__info {
    margin: 0 auto;
    max-width: calc(#{$max-width} - #{$grid-padding * 2});

    &--icon {
      float: right;
      height: $img-height;

      @include breakpoint($small-viewport) {
        float: left;
        margin-right: $grid-padding;
      }
    }

    &--logo-link {
      float: right;
    }

    &--logo {
      display: block;
      float: right;
      height: auto;
      max-width: 176px;

      @include breakpoint($small-viewport) {
        display: block;
      }

      img {
        height: auto;
        max-width: 176px;
      }
    }

    &--headlines {
      display: inline-block;
      float: left;
      text-align: left;
    }

    h2 {
      line-height: 0.8;
      margin: 0;
    }

    h3 {
      margin: 3px 0 0;
    }

    i {
      padding-right: 8px;

      @include breakpoint($small-viewport) {
        position: absolute;
        left: 0;
        top: 8px;
      }
    }
  }

  &__training-info {
    flex-direction: row-reverse;
    justify-content: space-between;
    display: none;
  }
}

.training {
  .head {
    cursor: pointer;
    margin: 8px 4px;

    &__info {
      margin: 0 auto;
      max-width: calc(#{$max-width} - #{$grid-padding * 2});
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      @include breakpoint($small-viewport) {
        display: block;
      }
    }
  }
}

.fluid-view {
  > .head {
    display: none;
  }
}

.training-view {
  .head__training-info {
    display: flex;
    align-items: center;
    position: relative;

    @include breakpoint($small-viewport) {
      display: block;
    }
  }

  .head__info {
    &--logo {
      display: none;

      @include breakpoint($small-viewport) {
        display: block;
      }
    }

    &--icon {
      padding-left: 40px;
    }
  }

  .training .head {
    display: none;
  }
}
