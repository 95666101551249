.c-fontsizer {
  background: $grey;
  display: none;
  transition: transform 0.5s ease-in-out, background-color 0.5s linear, border-color 0.5s linear;
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;

  &.close {
    transform: translateY(-100%);
  }

  &__icon {
    transition: color 0.5s linear;
    margin: 10px;

    &:first-child {
      font-size: 12px;
      margin: 15px;
    }

    &:last-child {
      font-size: 25px;
    }
  }

  &__range {
    max-width: 400px;
    width: 100%;
  }

  button {
    border: 2px solid $blue-dark;
    margin: 10px;
    width: 50%;
    outline: 0;
    border-radius: 2px;
    cursor: pointer;

    &:first-child {
      background: $white;
      margin-right: 5px;
    }

    &:last-child {
      background: $black;
      color: $white;
      margin-left: 5px;
      border-color: $black;
    }

    i {
      pointer-events: none;
    }
  }
}

.darkmode {
  .c-fontsizer {
    background: $black;

    &__icon {
      color: $white;
    }
  }

  button {
    &:first-child {
      border-color: $white;
    }

    &:last-child {
      border-color: $blue-dark;
    }
  }
}

.fluid-view {
  .c-fontsizer {
    display: block;
  }
}
