nav {
  padding: 0;
  background: $blue-light;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  width: 100%;

  .container {
    display: flex;
    justify-content: space-between;
    overflow: initial;
  }

  @include breakpoint($small-viewport) {
    padding: 0 $grid-padding;
    position: relative;
    top: 0;
  }

  button,
  a {
    align-content: center;
    background: transparent;
    border: 0;
    color: $blue-dark;
    cursor: pointer;
    display: flex;
    font-size: 22px;
    font-weight: 300;
    float: left;
    outline: 0;
    padding: 0 $grid-padding * 1.5;
    line-height: 50px;
    // margin: -9px 0 -5px; /* fix für touchbedienung, button ist grösser, als sein sichtbarer bereich. sonst klickt man aufs pdf in der pdf-view*/

    &.highlight {
      color: $black;
    }
  }

  .secondary-navigation {
    display: flex;
    align-content: center;
    width: 100%;
    justify-content: space-between;
  }

  span {
    display: none;
    font-family: $helvetica-light;
    font-size: 14px;
    font-style: normal;
    margin: 0 calc(#{$grid-padding} / 2);

    @include breakpoint($small-viewport) {
      display: inline-block;
    }
  }

  .imprint-link--app {
    display: none;
  }
}

body.app {
  nav {
    .imprint-link--app {
      display: flex;
    }

    .imprint-link {
      display: none;
    }
  }
}

#pdf-nav {
  display: none;

  .icon-left-dir {
    padding-right: 0;
  }

  .icon-right-dir {
    padding-left: 0;
  }
}

#nav-papers {
  display: none;
  justify-content: space-between;
  white-space: nowrap;
}

.training-view {
  #nav-papers {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    @include breakpoint($small-viewport) {
      justify-content: flex-start;
    }
  }

  .secondary-navigation {
    display: none;
  }
}

.fluid-view {
  nav {
    display: none;
  }
}

.ie nav {
  position: fixed;
  bottom: 0;
  top: initial;
}
