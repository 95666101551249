h1,
h2,
h3,
h4,
h5 {
  color: $black;
  line-height: 1;
}

h2 {
  color: $black;
  font-size: 24px;
  margin: $grid-padding * 2;
  text-transform: uppercase;
}

h3 {
  color: $black;
  font-size: 16px;
  font-weight: normal;
}

h4 {
  font-size: 20px;
  margin: 0;
  text-transform: uppercase;
}

h5 {
  color: $black;
  font-size: 24px;
  font-weight: bold;
  margin: $grid-padding * 2;
  text-transform: uppercase;
}

p {
  font-size: 15px;
}

a {
  color: $blue-dark;
  display: inline-block;
  text-decoration: none;
}

.content {
  margin: 0;
  padding: 0;
  padding-bottom: 60px;
  position: relative;

  @include breakpoint(small-viewport) {
    padding: calc($grid-padding / 2);
  }

  .timed-item.unreleased {
    display: none;
  }

}
