.c-fluid-overview {
  flex-direction: column;
  margin-top: 4px;
  margin-bottom: 4px;

  &.active {
    display: flex;
  }

  a {
    width: auto;
    background: $white;
    margin: 4px 8px;
    padding: 24px 16px 32px;
    font-size: 18px;
    text-align: left;

    span {
      margin: 4px 8px !important;
      font-size: 12px;
    }
  }
}

.fluid-view {
  .c-fluid-overview {
    display: none;
  }
}
