.l-flexrow,
[class^="l-flexrow--"],
[class*=" l-flexrow--"] {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.l-flexrow {
  &--center {
    justify-content: center;
  }
}
