html {
  box-sizing: border-box;
  min-height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  min-height: 100vh;
  background: $grey;
  font-family: $helvetica-light;
  font-weight: 300;
  line-height: 1.3;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  transition: background-color 0.5s linear;
  user-select: none;

  &.darkmode {
    background: $black;
  }
}
