.videos {
  display: none;
  
  video {
    height: auto;
    padding: $grid-padding 0;
    width: 100%;
  }

  &__video-item {
    margin: $grid-padding * 2 $grid-padding;
    overflow: hidden;

    span {
      float: right;
      font-size: 14px;
    }
  }
}
