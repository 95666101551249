.c-pagecompass {
  background: linear-gradient(to right, $blue-dark 0, $blue-dark 15px, $blue-light 15px, $blue-light 100%);
  height: 4px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 15px;

  &__settled {
    background: $blue-dark;
    width: 0;
    height: 4px;
    position: relative;
    transition: width 0.4s ease-in-out;

    &::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      position: absolute;
      right: -6px;
      top: -15px;
      background: $blue-dark;
      transform: rotate(45deg);
    }

    &::after {
      content: '';
      position: absolute;
      right: -12px;
      width: 24px;
      height: 24px;
      color: $white;
      text-align: center;
      top: -29px;
      background: $blue-dark;
      border-radius: 50%;
      border: 0;
    }
  }

  &__page-number {
    position: absolute;
    top: -26px;
    right: 0;
    z-index: 3;
    white-space: nowrap;
    color: $white;
    transform: translateX(50%);
    font-size: 14px;
    font-weight: bold;
  }
}
