.container {
  margin: auto;
  max-width: 100vw;
  overflow: hidden;

  &.active {
    display: block;
  }

  @include breakpoint($small-viewport) {
    max-width: $max-width;
  }
}
