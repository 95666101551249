#imprint-page {
  .head__info--logo {
    display: block;
  }

  .content .container {
    padding: 0 8px;
  }

  h2 {
    font-size: 24px;
    text-transform: uppercase;
    margin: 20px 0 10px 0;
  }

  h3 {
    font-size: 20px;
    text-transform: uppercase;
  }

  h4 {
    font-size: 18px;
    text-transform: uppercase;
  }
}
