.appstore-hint {
  display: inline-block;
  margin: 40px 8px;

  p {
    text-align: center;
    margin: 20px 0;
  }

  a.ios {
    width: 135px;
    padding: 0;
    float: left;
    &:hover {
      opacity: 0.7;
    }
  }

  a.android {
    width: 135px;
    padding: 0;
    float: right;
  }

  a:hover {
    opacity: 0.8;
  }
}