.audio {
  display: none;
  position: relative;
  z-index: 3;
  overflow: initial;

  &.active {
    display: block;
  }

  &__item {
    margin: $grid-padding * 2 $grid-padding;
  }
}

audio {
  width: 100%;
  margin: $grid-padding 0;

  &::-webkit-media-controls-panel {
    background: $white;
    border-radius: 5px;
  }
}
