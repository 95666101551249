.c-paper {
  max-width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  font-size: 0;
  line-height: 1.3;
  margin-top: 45px;

  @include breakpoint($med-viewport) {
    overflow-x: initial;
  }

  &.lock {
    -webkit-overflow-scrolling: auto;
  }

  &__page {
    background: $white;
    display: inline-block;
    flex-direction: column;
    margin: 0;
    max-height: calc(100vh - 45px);
    overflow-y: scroll;
    padding: 10px 10px 40px 10px;
    vertical-align: top;
    white-space: normal;
    -webkit-overflow-scrolling: touch;
    transition: color 0.5s linear, background-color 0.5s linear;
    width: 100vw;
    min-height: calc(100vh - 45px);
    font-size: 16px;

    @include breakpoint($med-viewport) {
      display: flex;
      margin: 10px;
      padding: 10px;
      max-height: initial;
      width: initial;
    }

    &#p1 {
      text-align: center;
    }
  }

  &__headline {
    font-size: 2em;
    margin: 20px 0;
    text-align: center;
    text-transform: none;
    color: inherit;
    font-weight: bold;
    max-width: calc(100vw - 20px);

    @include breakpoint($med-viewport) {
      font-size: 2.5em;
    }

    span {
      display: block;
      font-size: 0.6em;
      font-weight: normal;
      margin: 15px 0;
    }
  }

  &__subline {
    font-size: 1.25em;
    font-weight: bold;
    margin: 15px 0;
    text-transform: none;
    color: inherit;

    @include breakpoint($med-viewport) {
      font-size: 1.5em;
    }
  }

  &__image {
    height: auto;
    width: 100%;
    max-width: 500px;
    margin: 20px auto;
  }

  p {
    font-size: 1em;
    margin: 10px 0;
  }

  b,
  strong {
    font-weight: bold;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.darkmode {
  .c-paper {
    &__page {
      background: $grey-ultradark;
      color: $white;
    }
  }
}
