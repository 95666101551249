body#login-page {

  .xhtmlInstantForm {
    .keep-me-signed-in {
      text-align: left;
      line-height: 40px;
      label {
        cursor: pointer;
        input {
          margin-right: 10px;
        }
      }
    }
  }

}


$spinner-size: 20px;

.middle {
  display: flex;
  align-items: center;

  .head {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .content {
    width: 100%;
    padding: 0;
    @include breakpoint($low-viewport){
      padding-top: 70px;
    }
  }

  .center {
    max-width: 400px;
    padding: $grid-padding;
    text-align: center;
    width: 100%;
    margin: auto;

    @include breakpoint($small-viewport) {
      padding: calc(#{$grid-padding} * 2);
    }

    input[type='email'], input[type='password'], input[type='text'] {
      border: 1px solid $blue;
      border-radius: 0;
      box-shadow: none;
      font-size: 16px;
      font-weight: lighter;
      margin: $grid-padding 0;
      outline: 0;
      padding: $grid-padding;
      width: 100%;

      &:focus {
        border-color: $blue-dark;
      }

      -webkit-appearance: none;
    }

    button,
    input[type='submit'] {
      background: $blue-dark;
      border: 0;
      color: $white;
      font-size: 16px;
      font-weight: bold;
      margin: $grid-padding 0;
      outline: 0;
      padding: $grid-padding;
      text-transform: uppercase;
      width: 100%;
      border-radius: 0;
    }

    .cf {
      font-size: 14px;

      > div {
        margin: calc(#{$grid-padding} * 2) 0;
      }
    }

    .question {
      font-size: 18px;

      a {
        color: $black;
        span {
          color: $blue-dark;
        }
      }
    }

    .form-group {
      position: relative;

      &.error {
        input {
          border-color: $orange !important;
          box-shadow: none !important;
        }

        i::before {
          color: $orange !important;
        }
      }
    }

    i::before {
      color: $blue;
      font-size: 18px;
      line-height: 50px;
      position: absolute;
      right: $grid-padding;
      top: 0;
    }

    .error-message {
      color: $orange !important;
      padding: 0 0 $grid-padding;
    }

    .alert {
      color: $orange;
      a {
        color: $orange;
        text-decoration: underline;
      }
    }

    &.processing {
      input[type='submit'] {
        background: $grey-dark !important;
        color: $grey-dark;
        padding-right: 0 !important;
      }

      .cf .button-group {
        position: relative;

        &::before {
          animation: spin 2s infinite linear;
          -moz-animation: spin 2s infinite linear;
          color: $white;
          content: '\e834';
          display: inline-block;
          font-family: 'fontello';
          font-size: $spinner-size;
          font-style: normal;
          font-variant: normal;
          font-weight: normal;
          left: calc(50% - calc(#{$spinner-size} / 2));
          line-height: 1;
          position: absolute;
          text-align: center;
          text-decoration: inherit;
          text-transform: none;
          top: calc(50% - calc(#{$spinner-size} / 2));
          width: 1em;
          speak: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
  }

  .head__info--logo {
    display: block;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

